import React from "react";

import { 
    Layout,
    SimplePage
} from "../components";

const DMCAPage = () => {

    const blocks = [
        {
            content: 'If you believe that any content on Playdrop.gg infringes upon your copyright, please notify us in writing with the following information:',
        },
        {
            content: '(a) a physical or electronic signature of the copyright owner or a person authorized to act on their behalf;',
        },
        {
            content: '(b) identification of the copyrighted work claimed to have been infringed;',
        },
        {
            content: '(c) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;',
        },
        {
            content: '(d) your contact information, including your address, telephone number, and an email address;',
        },
        {
            content: '(e) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law;',
        },
        {
            content: '(f) a statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.',
        },
        /*{
            content: 'You can contact us at support@playdrop.gg for any DMCA related queries.',
        },*/
        /*{
            action: {
                label: 'support@playdrop.gg',
                onClick: () => window.open('mailto:support@playdrop.gg', '_blank'),
                type: 'inline',
            },
            content: 'If you have any questions or concerns about our privacy practices, please contact us at:',
        },*/
        {
            action: {
                label: 'contact us on our Discord channel',
                onClick: () => window.open('https://discord.com/invite/cPJYPhfPwQ', '_blank'),
                type: 'inline',
            },
            content: [
                'If you have any DMCA related queries, please',
            ],
        },
    ];
    const title = "DMCA";

    return (
        <Layout 
            title="DMCA"
        >
            <SimplePage
                blocks={blocks}
                title={title}
            />
        </Layout>
    )
}

export default DMCAPage;